import styled from "@emotion/styled";
import React from "react";
import { responsiveFont, settings } from '../grid/settings';

class TextComponent extends React.Component {

  render() {
    return (
      <Text>
        {this.props.text}
      </Text>
    )
  }
}

export default TextComponent



const Text = styled.div`

  white-space: pre-line;
  ${responsiveFont(settings.typo.p)};


  @media (max-width: ${settings.mobileWidth - 1}px){
    white-space: normal;
  }

  margin-bottom: 30px;
`;