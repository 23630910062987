import styled from "@emotion/styled";
import React from "react";
import ReactMarkdown from 'react-markdown';
import { settings } from '../grid/settings';


class HalfPageTextComponent extends React.Component {

  render() {
    const { html } = this.props;
    return (
      <Text>
        <ReactMarkdown source={html} escapeHtml={false} />
      </Text>
    )
  }
}

export default HalfPageTextComponent



const Text = styled.div`

  white-space: pre-line;
  max-width: 50%;

  @media (max-width: ${settings.mobileWidth - 1}px){
    white-space: normal;
  }



`;