

import DesignerIntroComponent from '../contents/designer-intro.component';
import HalfPageTextComponent from '../contents/half-page-text.component';
import IntroComponent from '../contents/intro.component';
import ProjectIntroComponent from '../contents/project-intro.component';
import RichTextComponent from '../contents/richtext.component';
import SideBySideTextComponent from '../contents/side-by-side-text.component';
import TextComponent from '../contents/text.component';
import TitleComponent from '../contents/title.component';

export { IntroComponent, TitleComponent, TextComponent, RichTextComponent, SideBySideTextComponent, HalfPageTextComponent, ProjectIntroComponent, DesignerIntroComponent };
