import styled from "@emotion/styled";
import React from "react";
import ReactMarkdown from 'react-markdown';
import { Container, Spacer } from '../grid/grid.component';
import { responsiveFont, settings } from '../grid/settings';
import { H2 } from '../grid/typography.component';

class TitleComponent extends React.Component {

  render() {
    const { html } = this.props;
    return (
      <Container>
        <TitleWrapper alignment={this.props.alignment}>
          <Spacer />
          <H2>
            {this.props.title}
          </H2>
          <Intro>
            {this.props.intro}
          </Intro>
          <ReactMarkdown source={html} escapeHtml={false} />
        </TitleWrapper>
      </Container>
    )
  }
}

export default TitleComponent


const TitleWrapper = styled.div`
    white-space: pre-wrap;
  text-align: ${props => props.alignment || 'left'};
  h2{
    text-align: ${props => props.alignment || 'left'};
    opacity: 0;
    transition: opacity 500ms;

    .tl-wrapper-status--exiting & {
      opacity: 0;
    }
    .tl-wrapper-status--entered & {
      opacity: 1;
    }
  }
  /* max-width: 50%; */
  
`;
const Intro = styled.div`
  font-size: 18px;
  ${responsiveFont(settings.typo.titleintro)};
  line-height: 1.55;

  opacity: 0;
  transition: opacity 1000ms;
  transition-delay: 0ms;

  .tl-wrapper-status--exiting & {
    opacity: 0;
  }
  .tl-wrapper-status--entered & {
    opacity: 1;
  }
`;
