import styled from "@emotion/styled";
import React from "react";
import IntersectionVisible from 'react-intersection-visible';
import ReactMarkdown from 'react-markdown';
import { settings } from '../grid/settings';


class RichTextComponent extends React.Component {

  constructor() {
    super()

    this.state = {
      intersectionActive: true
    }
  }

  onShow(entries) {
    this.setState({ intersectionActive: false });
  }
  render() {
    const { html } = this.props;
    return (
      <IntersectionVisible onShow={e => this.onShow(e)} active={this.state.intersectionActive}>
        <Text className={this.state.intersectionActive ? '' : 'inView'}>
          <ReactMarkdown source={html} escapeHtml={false} />
        </Text>
      </IntersectionVisible>

    )
  }
}

export default RichTextComponent



const Text = styled.div`

  white-space: pre-line;


  @media (max-width: ${settings.mobileWidth - 1}px){
    white-space: normal;
  }


  /* Fadein */
  /* .block-img{
    opacity: 0;
    transition: opacity 1000ms;
  }
  &.inView {
    .block-img{
      opacity: 1;
    }
  } */

`;