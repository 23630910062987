import React from "react";
import { Spacer } from '../grid/grid.component';

class SpacerComponent extends React.Component {

  render() {
    return (
      <Spacer></Spacer>
    )
  }
}

export default SpacerComponent


