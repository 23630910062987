import { css } from "@emotion/core";
import styled from "@emotion/styled";
import Link from "gatsby-link";
import React from "react";
import { TextComponent } from '../contents/index';
import { Cell, Row } from '../grid/grid.component';
import { settings } from '../grid/settings';
import { normal_link } from '../grid/typography.component';

class ProjectIntroComponent extends React.Component {

  render() {
    return (
      <ProjectIntroWrapper>
        <Row css={row_css}>
          <Cell.x5>
            <h2>kinuta
project</h2>
            <div className="intro">{'Playing with the dimensions of the dining table, we’ve experimented with the balance between lightness and heaviness.'}</div>
            <TextComponent text={`Norm Architects was founded in 2008. They work in the fields of industrial design, residential architecture, commercial interiors, photography and art direction. Each project—whether architecture, interiors, design or creative direction—is imbued with the same intrinsic quality: a simplicity that carries bigger ideas. Guided by the body and mind rather than by trends or technology, our projects explore ideas that not only look good.`} />
            <Link to={'/kinuta'} css={normal_link}>read full story</Link>
          </Cell.x5>
          <Cell.x2 />
          <Cell.x5><Image />
          </Cell.x5>
        </Row>
      </ProjectIntroWrapper>
    )
  }
}

export default ProjectIntroComponent



const ProjectIntroWrapper = styled.div`
  a{
    margin: 0 auto;
    display: block;
    width: max-content;
  }
`;

const Image = styled.div`

  height: 70vh;
  background-color: rgba(0,0,0,.1);
`;

const row_css = css`
  @media (max-width: ${settings.mobileWidth - 1}px){
    flex-direction: column-reverse;
  }
`;