import styled from "@emotion/styled";
import React from "react";
import { Cell, Row } from '../grid/grid.component';
import { fonts, responsiveFont, settings } from '../grid/settings';

class IntroComponent extends React.Component {

  render() {
    return (
      <Row>
        <Cell>
          <Intro>
            {this.props.text}
          </Intro>
        </Cell>
      </Row>
    )
  }
}

export default IntroComponent



const Intro = styled.div`

  font-style: italic;
  text-align: center;
  white-space: pre-line;
  font-family: ${fonts.sabon};
  ${responsiveFont(settings.typo.intro)};


  @media (max-width: ${settings.mobileWidth - 1}px){
    white-space: normal;
  }

  margin-bottom: 2rem;
`;