import { css } from "@emotion/core";
import styled from "@emotion/styled";
import { graphql, StaticQuery } from "gatsby";
import Link from 'gatsby-link';
import React from "react";
import { Cell, Container, Row } from '../grid/grid.component';
import { colors, responsiveFont, settings } from '../grid/settings';
import { external_link, H6, Input, normal_link } from '../grid/typography.component';

class FooterComponent extends React.Component {

  render() {
    return (
      <StaticQuery
        query={graphql`
          query {
            settings: prismicWebsiteSettings {
              data{
                footer_text_signup_for_newsletter
                footer_placeholder_signup
                footer_links_label
                footer_links {
                  link {
                    uid
                  }
                  text
                }
                footer_social_media_label
                footer_social_media_links {
                  link {
                    url
                  }
                  text
                }
                footer_language_label
                footer_language_english_text
                footer_language_japanese_text
                footer_presskit_label
                footer_presskit_link_text
                footer_contact_label
                footer_contact_email {
                  url
                }
              }
            }
          }
        `
        }
        render={data => {
          const {
            footer_text_signup_for_newsletter,
            footer_placeholder_signup,
            footer_links_label,
            footer_links,
            footer_social_media_label,
            footer_social_media_links,
            footer_language_label,
            footer_language_english_text,
            footer_language_japanese_text,
            footer_presskit_label,
            footer_presskit_link_text,
            footer_contact_label,
            footer_contact_email,

          } = data.settings.data;
          return (
            <div>
              <FooterTopWrapper>
                <Container>
                  <Row wrap={'wrap'}>
                    <Cell css={cell_css}>
                      <H6>Karimoku  Case Study</H6>

                      <br /><br /><br /><br />

                      {footer_text_signup_for_newsletter}<br /><br />
                      <Input type="text" placeholder={footer_placeholder_signup} /> <button css={external_link}></button>
                    </Cell>
                    <Cell.x2 />
                    <Cell.x2 css={cell_css}>
                      <Faded>{footer_links_label}</Faded><br />
                      <ul>
                        {footer_links.map(link => (
                          <li key={link.text}><Link to={`/${link.link.uid}`}>{link.text}</Link></li>
                        ))}
                      </ul>

                      <br /><br />
                    </Cell.x2>
                    <Cell.x2 css={cell_css}>
                      <Faded>{footer_social_media_label}</Faded><br />
                      <ul>
                        {footer_social_media_links.map(link => (
                          <li key={link.text}><a href={`${link.link.url}`} target="_blank" rel="noopener noreferrer">{link.text}</a></li>
                        ))}
                      </ul>
                      <br />
                      <br />
                      <Faded>{footer_language_label}</Faded><br />
                      {footer_language_english_text} | {footer_language_japanese_text}
                      <br /><br /><br /><br />
                    </Cell.x2>
                    <Cell.x2 css={cell_css}>
                      <Faded>{footer_presskit_label}</Faded><br />
                      <a href="/" css={normal_link}>{footer_presskit_link_text}</a><br />
                      <br /><br />
                      <Faded>{footer_contact_label}</Faded><br />
                      <a href={footer_contact_email.url} css={external_link}>{footer_contact_email ? footer_contact_email.url.replace('mailto:', '') : 'Contact'}</a>
                    </Cell.x2>
                  </Row>

                </Container>
              </FooterTopWrapper>

              <FooterBottomWrapper>
                <Container>
                  <Row>
                    <Cell>Copyright © Karimoku</Cell>
                    <Cell.x2><Link to={'/terms-and-conditions'}>Terms & Conditions</Link></Cell.x2>
                    <Cell.x2><Link to={'/privacy-policy'}>Privacy Policy</Link></Cell.x2>
                  </Row>
                </Container>
              </FooterBottomWrapper>

            </div>
          )
        }
        }
      />

    )
  }
}

export default FooterComponent



const FooterTopWrapper = styled.div`

  background-color: ${colors.beigedark};
  padding: 50px 0;

  ${responsiveFont(settings.typo.small)};
  line-height: 1.25;

  input {
    width: 70%;
    float: left;
  }

  ul{
    margin: 0;
    padding: 0;
    list-style: none;
  }
  li{
    margin-bottom: 1.3em;
  }
`;

const Faded = styled.div`
  opacity: .5;
`;
const cell_css = css`
  min-width: 190px;
`;

const FooterBottomWrapper = styled.div`

  background-color: #E4E2D9;
  padding: 20px 0;

  ${responsiveFont(settings.typo.small)};
  line-height: 1.25;

`;