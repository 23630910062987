import styled from "@emotion/styled";
import React from "react";
import { RichTextComponent } from '../contents/index';
import { responsiveWidth, settings } from '../grid/settings';
// import IntersectionVisible from 'react-intersection-visible';


class SideBySideTextComponent extends React.Component {

  constructor() {
    super()

    this.state = {
      isMobile: false
    }

    this.handleWindowResize = this.handleWindowResize.bind(this);
    this.htmlWrapper = React.createRef();
  }
  handleWindowResize = () => {
    this.setState({ isMobile: window.innerWidth < settings.mobileWidth });
  }
  modeCaptions() {
    if (!this.htmlWrapper.current) return;
    this.htmlWrapper.current.querySelectorAll('span.caption-on-side').forEach(e => e.parentNode.removeChild(e));

    const captions = this.htmlWrapper.current.querySelectorAll('p.caption-on-side');
    for (var i = 0; i < captions.length; i++) {
      var c = document.createElement("span");
      c.className = 'caption-on-side';
      c.innerHTML = captions[i].innerHTML;
      // console.log(captions[i]);
      // console.log(captions[i].previousElementSibling);
      captions[i].previousElementSibling.appendChild(c);
    }
  }
  componentDidMount() {
    this.modeCaptions();
    this.handleWindowResize();
    window.addEventListener('resize', this.handleWindowResize);
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowResize);
  }

  renderMobile() {
    const { items, stack } = this.props;
    return (
      <TextRow wrap={'wrap'} stack={stack}>
        {
          items && items.map((item, i) => {
            const last = stack !== 'normal' ? i + 2 === items.length : i === items.length - 1;
            return (
              <div className={`cell ${last ? 'last' : ''}`} style={{ order: stack !== 'normal' ? i % 2 === 0 ? i + 2 : i : i }} key={`item-${i}`}>
                <RichTextComponent html={item.text.html} />
              </div>
            )
          })
        }
      </TextRow>
    )
  }
  renderDesktop() {
    const { items } = this.props;
    return (
      <TextRow wrap={'wrap'}>
        <div className="col">
          {
            items && items.map((item, i) => {
              return (
                i % 2 === 0 ? <div className={`cell`} key={`item-${i}`}>
                  <RichTextComponent html={item.text.html} />
                </div> : ''
              )
            }
            )
          }
        </div>
        <div className="col">
          {
            items && items.map((item, i) => {
              return (
                i % 2 === 1 ? <div className={`cell`} key={`item-${i}`}>
                  <RichTextComponent html={item.text.html} />
                </div> : ''
              )
            })
          }
        </div>
      </TextRow>
    )
  }
  renderForScreen() {
    if (this.state.isMobile) {
      return this.renderMobile();
    } else {
      return this.renderDesktop();
    }
  }
  render() {
    // const { items } = this.props;
    return (
      <div ref={this.htmlWrapper}>
        {this.renderForScreen()}
      </div>
    )
  }
}

export default SideBySideTextComponent

const TextRow = styled.div`

  
  display: inline-flex;
  flex-wrap: wrap;
  align-items: flex-start;
  
  .cell{
    min-width: 100%;
  }
  .col{
    display: flex;
    flex-direction: column;

    width: 41.66666666666667%;
    &:first-of-type{
      margin-right: 16.666666666666668%;
    }

    @media (max-width: ${settings.mobileWidth - 1}px){
      display: block;
      flex: none;
      width: 100%;
      max-width: 100%;
      &:first-of-type{
        margin-bottom: 100px;
        margin-right: 0;
      }
    }

  }

  div{
    font-size: 0;
  }
  h1{
    text-align: center;
  }
  
  .cell{
    
    div:not(:empty){
      ${responsiveWidth('margin-bottom', settings.spacers.min, settings.spacers.max)}
    }
    
    &.last{
      margin-top: auto;
      div{
        margin-bottom: 0;
      }
    }
  }
  @media (min-width: ${settings.mobileWidth}px){
    .cell:last-of-type{
      margin-top: auto;
      div{
        margin-bottom: 0;
      }
    }
  }
  

  @media (max-width: ${settings.mobileWidth - 1}px){
    .block-img .caption-on-side{
      display: none;
    }
  }
  @media (min-width: ${settings.mobileWidth}px){
    .block-img ~ .caption-on-side{
      opacity: 0;
      margin-top: 0;
      line-height: 0;
    }
  }
  .col:first-of-type{
    .block-img{

      .caption-on-side{
        left: auto;
        right: 0;
        transform: translateX(100%);
        width: 26%;
        text-align: left;
      }
    }
  }

`;

