import { css } from "@emotion/core";
import styled from "@emotion/styled";
import Image from "gatsby-image";
import React from "react";
import { TextComponent } from '../contents/index';
import { Cell, Row } from '../grid/grid.component';
import { settings } from '../grid/settings';
import { external_link } from '../grid/typography.component';

class DesignerIntroComponent extends React.Component {

  render() {
    const { name, intro, text, website, image_caption, image } = this.props;
    return (
      <DesignerIntroWrapper className="designer-intro default-fade">
        <Row css={row_css}>
          <Cell.x5 css={cell_css}>
            {image && <Image css={image_css} {...image.localFile.childImageSharp} />}
            <div className="caption" css={caption_css}>{image_caption}</div>
          </Cell.x5>
          <Cell.x2 css={cell_css} />
          <Cell.x5 css={cell_css}>
            <h2>{name}</h2>
            <div className="intro">{intro}</div>
            <TextComponent text={text} />

            <a href={website && website.url} css={external_link} target="_blank" rel="noopener noreferrer">visit website</a>
          </Cell.x5>
        </Row>
      </DesignerIntroWrapper>
    )
  }
}

export default DesignerIntroComponent



const DesignerIntroWrapper = styled.div`
  a{
    margin: 0 auto;
    display: block;
    width: max-content;
  }

  h2{
    text-align: center;
  }
`;

const image_css = css`
  @media (max-width: ${settings.mobileWidth - 1}px){
    margin-bottom: 4vw;
  }
`;
const caption_css = css`
  @media (max-width: ${settings.mobileWidth - 1}px){
    display: none;
  }
`;

const row_css = css`
`;
const cell_css = css`
  
`;